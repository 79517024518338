<template>
	<div>
		<h1 class="main-title">Сессии SeoApi WS</h1>
		<p class="sessions-total">Всего сессий: {{ total }}</p>
		<v-data-table
			class="sessions-table"
			:headers="headers"
			:items="sortedSessions"
			:loading="isLoading"
		>
			<template v-slot:item.progress="{ item }">
				<span>{{ item.progress }}</span>
				<v-progress-linear :value="item.progress"></v-progress-linear>
			</template>

			<template v-slot:item.started_at="{ item }">
				<span>{{ formatDate(item.started_at) }}</span>
			</template>

			<template v-slot:item.finished_at="{ item }">
				<span>{{ formatDate(item.finished_at) }}</span>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
	data () {
		return {
			isLoading: false,
			headers: [
				{
					text: 'session_id',
					value: 'session_id',
					align: 'center',
					width: '24%',
					divider: true
				},
				{
					text: 'priority',
					value: 'priority',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'status',
					value: 'status',
					align: 'center',
					width: '7%',
					divider: true
				},
				{
					text: 'progress',
					value: 'progress',
					align: 'center',
					width: '15%',
					divider: true
				},
				{
					text: 'queries',
					value: 'queries',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'total',
					value: 'total',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'done',
					value: 'done',
					align: 'center',
					width: '6%',
					divider: true
				},
				{
					text: 'started_at',
					value: 'started_at',
					align: 'center',
					width: '15%',
					divider: true
				},
				{
					text: 'finished_at',
					value: 'finished_at',
					align: 'center',
					width: '15%',
					divider: true
				}

			]
		}
	},
	mounted () {
		this.fetchData()
	},
	computed: {
		...mapState({
			total: state => state.sessions.totalWS,
			sessions: state => state.sessions.sessionsWS
		}),

		sortedSessions () {
			if (!this.sessions) {
				return []
			}
			return Object.values(this.sessions).sort((a, b) => b.started_at - a.started_at)
		}
	},
	methods: {
		...mapActions({
			getSessionsWS: 'sessions/getSessionsWS'
		}),

		async fetchData () {
			try {
				this.isLoading = true
				await this.getSessionsWS()
			} catch (error) {
				this.$notify({ type: 'error', title: error })
			} finally {
				this.isLoading = false
			}
		},
		formatDate (timestamp) {
			if (!timestamp) {
				return '-'
			}
			const date = new Date(timestamp * 1000).toLocaleString()
			const formattedDate = date.split(', ').reverse()
			formattedDate[1] = formattedDate[1].split('.').join('-')

			return formattedDate.join(', ')
		}
	}

}

</script>
<style>
.main-title {
	margin: 20px 0px;
}

.sessions-total {
	margin-top: 30px;
	text-align: center;
}

.sessions-table {
	margin-top: 30px;
	border-top: 1px solid rgba(0,0,0,.12);
}
</style>
